<script>

import { mapGetters, mapActions } from 'vuex';
import Swal from "sweetalert2";
import MonthlyPerformance from './components/monthly-performance'
import Weights from './components//weights'
import NetAssetValueGrowthChart from './components/net-asset-value-growth-chart'
import TaxLossHarvest from './tax-loss-harvest'
import { camelCase } from 'lodash';
import VueHtml2pdf from 'vue-html2pdf'
import http from '@/oauth-client'
import explorerHttp from '@/explorer-client'

export default {
  components: {
    NetAssetValueGrowthChart,
    MonthlyPerformance,
    TaxLossHarvest,
    Weights,
    VueHtml2pdf
  },
  created() {
    this.camelCase = camelCase;
    this.loadPredefinedRiskProfiles();
  },
  props: {
    impersonation: {
      type: Boolean
    }
  },
  data() {
    return {
      statColumns: {
        riskProfile: {
          visible: false
        }
      },
    predefinedRiskProfiles: [],
      deleting: null,
      taxLossHarvest: null,

      aumFormVisible: false,
      aumProcessing: false,
      aum: 0,
      editedPortfolio: null,

      selectedItem: null,
      selectedItemType: null,
      selectedPortfolio: null,
      expanded: {

      },
      instruments: [],
      netAssetValueGrowthCharts:[],
      monthlyPerformance: null,
      compositionWeights: [],

      executing: null,

      pdfProcessing: false
    };
  },
  computed: {

    ...mapGetters('auth', {
      currentUser: 'currentUser',
      permissions: 'permissions'
    }),
     ...mapGetters('riskprofiles', {
    
      riskProfiles: 'activeItems'
    }),
    ...mapGetters('portfolios', {
      loading: 'loading',
      items: 'items',
    //  deleting: 'deleting'
    }),
    total() {
      return this.items.find(x => x.id === '$$TOTAL$$');
    }
  },
  methods: {

    ...mapActions('portfolios', {
      deletePortfolio: 'deleteUserPortfolio',
      execute: 'execute',
      loadUserPortfolios: 'loadUserPortfolios',
    }),
    ...mapActions('execution', {
      saveUserSettings: 'saveUserSettingsById'
    }),
    showAumForm(portfolio){
      this.aum = portfolio.userSettings.aus;
      this.editedPortfolio = portfolio;
      this.aumFormVisible = true;
    },
    async applyAUM() {
      const value = {...this.editedPortfolio.userSettings };
      value.aus = this.aum;
      this.aumProcessing = true;
      try {
        await this.saveUserSettings({ id: this.editedPortfolio.id, value});
      } catch (e) {
        console.error(e);
      }
      this.aumProcessing = false;
      this.aumFormVisible = false;
      await this.loadUserPortfolios();
    },
    async onExecute( { id, name }) {
      this.executing = id;
      await this.execute({ id, name} );
      this.executing = null;
    },
    validPortfolioInstruments(portfolio) {
      return portfolio.instruments.filter(instrument =>
        (portfolio.data.basket.items[camelCase(instrument.name)] || {}).position !== 0
        || (portfolio.data.basket.items[camelCase(instrument.name)] || {}).proposedPosition !== 0
      );
    },
    expand(portfolio) {
     
      const o = {...this.expanded};
      o[portfolio.id] = o[portfolio.id] ? !o[portfolio.id] : true;
      this.expanded = o;
      console.log('expand', this.expanded);
    },
    selectPortfolio(p) {
      this.selectedPortfolio = p;
      this.selectedItem = {
        id: p.id,
        data: p.stat,
        instruments: p.originalInstruments,
        originalInstruments: p.originalInstruments,
        name: p.name,
        benchmark: p.benchmark
      };
      this.instruments = p.instruments;
      this.selectedItemType = 'portfolio';
      if(p.data && p.data.monthlyPerformance) {
        this.monthlyPerformance = p.data.monthlyPerformance;
      } else {
        this.monthlyPerformance = null;
      }
      if(p.data && p.data.netAssetValueGrowthCharts) {
        this.netAssetValueGrowthCharts = p.data.netAssetValueGrowthCharts;
      } else {
        this.netAssetValueGrowthCharts = [];
      }

      if(p.data && p.data.compositionWeights) {
        this.compositionWeights = p.data.compositionWeights;
      } else {
        this.compositionWeights = [];
      }
    },
    selectInstrument(p, i) {
      this.selectedPortfolio = p;
      this.selectedItem = i;
      this.selectedItemType = 'instrument';
      this.instruments = p.instruments;
      if(p.data && p.data.instrumentMonthlyPerformance) {
        this.monthlyPerformance = p.data.instrumentMonthlyPerformance[camelCase(i.name)] || null;
      } else {
        this.monthlyPerformance = null;
      }
      
      if(p.data && p.data.instrumentNetAssetValueGrowthCharts) {
        this.netAssetValueGrowthCharts = p.data.instrumentNetAssetValueGrowthCharts[camelCase(i.name)] || [];
      } else {
        this.netAssetValueGrowthCharts = [];
      }
    },
    tryToDelete(id, name) {
      
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(async result =>  {
        if (result.value) {
          this.deleting = id;
          await this.deletePortfolio({ id, name }).then(() => {
            Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
          });
          await this.loadUserPortfolios();
          this.deleting = null;
        }
      });
    },
    calcSumDailyReturn () {
      let sum = 0;
      this.items.forEach(portfolio => {
        sum += this.calcDailyReturn(portfolio);
      })
      return parseFloat(sum.toFixed(2));
    },
    calcSumMonthlyReturn () {
      let sum = 0;
      this.items.forEach(portfolio => {
        sum += this.calcMonthlyReturn(portfolio);
      })
      return parseFloat(sum.toFixed(2));
    },
    calcSumTotalReturn () {
      let sum = 0;
      this.items.forEach(portfolio => {
        sum += this.calcTotalReturn(portfolio);
      })
      return parseFloat(sum.toFixed(2));
    },
    // calcDailyReturn (portfolio) { 
    //   return parseFloat((portfolio.data && portfolio.data.performenceInformation.dailyReturn || 0).toFixed(2));
    // },
    // calcMonthlyReturn (portfolio) {
    //   return  parseFloat((portfolio.data && portfolio.data.basket ? this.calcReturn(portfolio.data.basket, 'monthlyReturn') : 0).toFixed(2));
    // },
    // calcTotalReturn (portfolio) {
    //   return parseFloat((portfolio.data &&  portfolio.data.basket ? this.calcReturn(portfolio.data.basket, 'totalReturn') : 0).toFixed(2));
    // },
    calcReturn (basket, propName) {
      let sum = 0;
       const keys = Object.keys(basket.items);
     
        keys.forEach(key => {
        const bi = basket.items[key];
        sum += bi[propName] || 0;
      })
      return parseFloat(sum.toFixed(2));
    },
    isRowVisible (/*basket*/) {
      return true;
      // basket = basket || {};
      // return basket.position || basket.proposedPosition || basket.dailyReturn || basket.monthlyReturn || basket.totalReturn;
    },
    onProgress($event) {
      console.log('onProgress');
      console.log($event)
    },
    hasStartedGeneration() {
      console.log('hasStartedGeneration');
    },
    generateReport () {
      console.log('generateReport');
      this.pdfProcessing = true;
      window.setTimeout(()=>this.$refs.html2Pdf.generatePdf(), 500);
    },
    hasGenerated () {
    
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then(() => {
        this.pdfProcessing = false;
      }).save();
    },
    getSelectedPortfolioName() {
      if (this.selectedPortfolio !== null) {
        return this.selectedPortfolio.displayName;
      } else {
        return "Selected Portfolio Performance";
      }
    },
    getPortfolioRiskProfile(riskProfileId) {
      return [...this.riskProfiles,...this.predefinedRiskProfiles].find(x => x.id === riskProfileId).name;
    },
    async loadPredefinedRiskProfiles() {
      const userResp = await explorerHttp.get(`data/User/objects/current`);
      const user = userResp.data;
      if( user.Company?.Id) {
        const resp = await http.get('api/predefined-risk-profiles?companyId=' + user.Company?.Id);
        this.predefinedRiskProfiles = resp.data;
      }
     
    },
  }
};
</script>

<template>

  <div>
    <b-modal
      v-model="aumFormVisible"
      title="Edit AUM"
      title-class="text-dark font-18"
      hide-footer
    >
      <div>
         <input class="form-control mb-2" v-model.number="aum"/>
        <div>
          <b-button class="ml-1 float-left" @click="aumFormVisible = false"> Cancel</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="aumProcessing" @click="applyAUM">
            <i class="fa fa-spin fa-spinner" v-if="aumProcessing"></i>
            Apply
          </button>
        </div>
      </div>
    </b-modal>

    <tax-loss-harvest v-if="taxLossHarvest" :obj="taxLossHarvest" @close="taxLossHarvest = null"></tax-loss-harvest>
    <div class="card p-0 cardc8">
      <div class="card-content m-3">
     
        <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
          <h5 class="mr-auto">Portfolios</h5>
          
          <div class="ml-auto">
            <b-dropdown left variant="white">
              <template v-slot:button-content>
                <i class="fa fa-columns m-0 text-muted font-size-20"></i>
              </template>
              <div class="dropdown-item bg-white text-body">
                <div class="form-group"><input type="checkbox" v-model="statColumns.riskProfile.visible" id="statColumns_riskProfile"/>
                  <label for="statColumns_riskProfile" class="ml-2">Risk Profile</label>
                </div>
              </div>
            </b-dropdown>

            <button type="button" class="btn btn-primary ml-2" @click="generateReport" :disabled="pdfProcessing || (loading && !executing) || selectedPortfolio === null || selectedItemType !== 'portfolio'">
              <i class="fa fa-spin fa-spinner" v-if="pdfProcessing"></i>
              <i class="fa fa-file-pdf" v-if="!pdfProcessing"></i>
            </button>
          </div>
        </div>
        <div v-if="loading && !executing" class="p-4">
          <i class="fa fa-spinner fa-spin mr-2"></i>Loading...
        </div> 
        <div v-if="!loading || executing">
          <div class="text-muted mt-4" v-if="items.length === 0">
            No portfolios found...
          </div>

          <div  class="table-responsive" v-if="items.length > 0">
            <table class="table table-borderless ">
              <thead>
                <tr>
                  <th class="col-auto" v-if="!impersonation"></th>
                  <th class="col-auto"  v-if="!impersonation"></th>
                  <th class="col" style="min-width:300px"></th>
                  <th v-if="statColumns.riskProfile.visible">Risk Profile</th>
                  <th>Status</th>
                  <th  style="width:120px;max-width:120px;">
                    Position 
                  </th>
                  <th  class="col">
                    Proposed Position 
                  </th>
                  <th  class="col">
                    Daily Return
                  </th>
                  <th  class="col">
                    Monthly Return
                  </th>
                  <th  class="col">
                    Total Return
                  </th>
                  <th class="text-center col text-nowrap">

                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="portfolio in items" >
                  <tr :key="portfolio.id" :class="{'table-warning': portfolio.id === (selectedItem || {}).id}"  v-if="portfolio.id !== '$$TOTAL$$'">
                    <td  v-if="!impersonation">
                      <router-link class="btn btn-primary btn-sm text-nowrap" :to="{name:'execution', params: { id: portfolio.id }}" v-if="!deleting">
                        Execute
                      </router-link>
                     
                    </td>
                    <td  v-if="!impersonation">
                      <router-link class="btn btn-light btn-sm text-nowrap" :to="{name:'tax-loss-harvest', params: { id: portfolio.id }}" v-if="portfolio.data && portfolio.data.taxLoss">
                        Tax Loss Harvest
                      </router-link>
                    </td>
                    <td  class="text-nowrap" style="padding-right:30px; position:relative;">
                      <a 
                        class="mr-2"
                        href="javascript:void(0)"
                        @click="selectPortfolio(portfolio)"
                      >
                        {{portfolio.displayName}}
                      </a>
                      <b class="mr-2" v-if="portfolio.userSettings">
                        {{portfolio.userSettings.aus}} 
                        <a href="javascript:void(0)"  @click="showAumForm(portfolio)"  v-if="!impersonation && !deleting"><i class="fa fa-pen"></i></a>
                      </b>
                      <b class="mr-2" v-if="portfolio.userSettings">
                        [{{portfolio.userSettings.ausCurrency}}]
                      </b>
                      <a style="position: absolute; right:0; top:5px;" @click="expand(portfolio)" >
                        <b v-if="expanded[portfolio.id]"><i class="fa fa-chevron-up "></i></b>
                        <b v-if="!expanded[portfolio.id]"><i class="fa fa-chevron-down"></i></b>
                      </a>
                    </td>
                    <td v-if="statColumns.riskProfile.visible">
                      {{getPortfolioRiskProfile(portfolio.constraints.riskProfileId)}}
                    </td>
                    <th><span class="badge badge-info">{{portfolio.data.status}}</span>  </th>
                    <td>
                    
                    </td>
                    <td>
                    
                    </td>
                    <td>
                      <span v-if="portfolio.data && portfolio.data.performenceInformation">{{portfolio.data.performenceInformation.dailyReturn.toFixed(2)}}</span>
                
                    </td>
                    <td>
                      <span v-if="portfolio.data && portfolio.data.performenceInformation">{{portfolio.data.performenceInformation.monthlyReturn.toFixed(2)}}</span>
                    </td>
                    <td>
                      <span v-if="portfolio.data && portfolio.data.performenceInformation">{{portfolio.data.performenceInformation.totalReturn.toFixed(2)}} </span>
                    </td>
                    <td class="text-center col text-nowrap" v-if="!impersonation">
                      <router-link
                        v-if="!deleting"
                        class="font-size-14 mr-2"
                        :to="{name: 'portfolio-edit', params: { id: portfolio.id}}"
                      >
                        <i class="fa fa-pen"></i>
                      </router-link>
                      <i class="fa fa-spinner fa-spin mr-1" v-if="deleting === portfolio.id"/>
                      <b-dropdown right toggle-class="arrow-none card-drop" class="d-inline-block" variant="white" v-if="!deleting">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-vertical"></i>
                        </template>

                        <b-dropdown-item v-if="!deleting" :to="{name: 'portfolio-edit', params: { id: portfolio.id, clone: true}}">
                          <a class="font-size-14">
                            <i class="fa fa-clone mr-1"/>
                            Clone
                          </a>
                        </b-dropdown-item>

                        <b-dropdown-item @click="tryToDelete(portfolio.id, portfolio.name)">
                          <a href="javascript:void(0)" class="font-size-14  text-danger" :disabled="deleting" >
                            <i class="fa fa-trash mr-1" />
                            Delete
                          </a>
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                  <template v-for="instrument in validPortfolioInstruments(portfolio)">
                    <tr :key="portfolio.id + instrument.id" v-if="expanded[portfolio.id] && isRowVisible(portfolio.data.basket.items[camelCase(instrument.name)] || {})" 
                    :class="{'table-warning': instrument.id === (selectedItem || {}).id}">
                    
                      <td class="text-nowrap pl-4" :colspan="impersonation ? 2 : 4">
                        <span class="ml-2">
                          <a href="javascript:void(0)"  @click="selectInstrument(portfolio, instrument)"> 
                            {{instrument.name}}
                          </a>
                        </span>
                      </td>
                      <td v-if="statColumns.riskProfile.visible">

                      </td>
                      <td>
                        <span v-if="portfolio.data.basket">
                          {{(portfolio.data.basket.items[camelCase(instrument.name)] || {}).position || 0}}
                        </span> 
                      </td>
                      <td>
                        <span v-if="portfolio.data.basket">
                          {{(portfolio.data.basket.items[camelCase(instrument.name)] || {}).proposedPosition || 0}}
                        </span>
                      </td>
                      <td>
                        <span v-if="portfolio.data.basket">
                          {{((portfolio.data.basket.items[camelCase(instrument.name)] || {}).dailyReturn || 0).toFixed(2)}}
                        </span>
                      </td>
                      <td>
                        <span v-if="portfolio.data.basket">
                        {{((portfolio.data.basket.items[camelCase(instrument.name)] || {}).monthlyReturn || 0).toFixed(2)}}
                        </span>
                        
                      </td>
                      <td>
                        <span v-if="portfolio.data.basket">
                          {{((portfolio.data.basket.items[camelCase(instrument.name)]  || {}).totalReturn || 0).toFixed(2)}}
                        </span>
                      
                      </td>
                      <td  class="text-center col text-nowrap" v-if="!impersonation">
                        
                      </td>
                    </tr>
                  </template>
                </template>
                <tr class="bg-light" v-if="total" :key="total.id">
                  <td v-if="!impersonation">
                    
                  </td>
                  <td v-if="!impersonation">
                    
                  </td>
                  <td style="min-width: 300px;">
                    <b><a href="javascript:void(0)" @click="selectPortfolio(total)">Total</a></b>
                  </td>
                  <td v-if="statColumns.riskProfile.visible">
                    
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    <b>{{total.data.performenceInformation.dailyReturn.toFixed(2)}}</b> 
                  </td>
                  <td>
                    <b>{{total.data.performenceInformation.monthlyReturn.toFixed(2)}}</b> 
                  </td>
                  <td>
                    <b>{{total.data.performenceInformation.totalReturn.toFixed(2)}} </b>
                  </td>
                  <td class="text-center col text-nowrap" v-if="!impersonation">
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="text-right p-2 mt-4" v-if="[...riskProfiles,...predefinedRiskProfiles].length > 0 && !impersonation">
          
            <!-- <button class="btn btn-c8 mr-2">
              <span class="btn-content"><i class="fa fa-upload mr-1 ml-1"/> Upload New Portfolio</span>
            </button> -->
            <router-link class="btn btn-c8" :to="{name: 'portfolio-edit', params: { id: 'new'}}">
              <span class="btn-content"><i class="fa fa-plus mr-1"/> Add New Portfolio</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="card cardc8"  v-if="selectedItem && netAssetValueGrowthCharts && netAssetValueGrowthCharts.length > 0">
      <div class="card-content">
        <div class="card-body pt-3">
          <b-tabs  content-class="p-3 text-muted">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Performance</span>
              </template>
              <div class="row mb-4">
                <div class="col">
                  <NetAssetValueGrowthChart 
                    :stat="netAssetValueGrowthCharts" 
                    :instruments="instruments"
                    :colors="{}"
                    hide-chart-hint
                    embedded
                    >
                  </NetAssetValueGrowthChart>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="monthlyPerformance">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Monthly Performance</span>
              </template>
              <div class=" pt-2">
                <monthly-performance 
                  :stat="monthlyPerformance" 
                  :instruments="instruments"
                   embedded
                >
                </monthly-performance>
              </div>
            </b-tab>
           <b-tab  v-if="selectedItemType === 'portfolio' && selectedItem.id !== '$$TOTAL$$'">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Weights</span>
              </template>
              <div class=" pt-2">
                <weights 
                  :no-card="true"
                  :weights="compositionWeights" 
                  :children="selectedItem.originalInstruments || selectedItem.instruments" 
                >
                </weights>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <vue-html2pdf
      v-if="selectedItem !== null && selectedItemType === 'portfolio'"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="getSelectedPortfolioName()"
      :pdf-quality="3"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"

      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- Logo >>>> -->
        <img src="@/assets/images/c8_logo_main.jpg" class="img-fluid rounded mx-auto d-block" style="margin-left:100px;margin-right:100px;width:600px; margin-top:250px">
        <div class="d-flex justify-content-center" style="margin-bottom:170px; margin-top:150px">
          <h4 class="display-4">C8 Studio</h4>
        </div>
        <div class="d-flex justify-content-center">
          <h2>C8 Studio Available Indices</h2>
        </div>

        <div class="html2pdf__page-break" />
        <!-- Logo <<<< -->

        <!-- Performance chart >>>> -->
        <div style="margin:50px" class="print">
          <h5>
            {{getSelectedPortfolioName()}}
          </h5>
          <hr />

          <h5 class="card-title" style="margin-top:20px">{{$t('pages.indexView.indexPerformance-title')}}</h5>
          <h6 class="card-subtitle text-muted" style="margin-top:10px; margin-bottom:20px"><span v-html="$t('pages.indexView.indexPerformance-subtitle')"></span></h6>
          <div class="card-text">
            <NetAssetValueGrowthChart 
              :stat="netAssetValueGrowthCharts" 
              :instruments="instruments"
              :colors="{}"
              hide-chart-hint
              :pdf="true"
            />
          </div>
        </div>

        <div class="html2pdf__page-break" />
        <!-- Performance chart <<<< -->
        
        <!-- Instruments Chart >>>> -->
        <div style="margin:50px" class="print">
          <table class="table table-centered table-stripped table-sm datatable table-hover dt-responsive nowrap">
            <thead>
              <tr>
                <th class="col-auto" v-if="!impersonation"></th>
                <th class="col-auto"  v-if="!impersonation"></th>
                <th class="col" style="min-width:300px"></th>
                <th>Status</th>
                <th  style="width:120px;max-width:120px;">
                  Position 
                </th>
                <th  class="col">
                  Proposed Position 
                </th>
                <th  class="col">
                  Daily Return
                </th>
                <th  class="col">
                  Monthly Return
                </th>
                <th  class="col">
                  Total Return
                </th>
                <th class="text-center col text-nowrap">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="instrument in validPortfolioInstruments(selectedPortfolio)" :key="selectedPortfolio.id + instrument.id"> 
                <td class="text-nowrap pl-4" :colspan="impersonation ? 2 : 4">
                  <span class="ml-2">
                    <a href="javascript:void(0)"> 
                      {{instrument.name}}
                    </a>
                  </span>
                </td>
                <td>
                  <span v-if="selectedPortfolio.data.basket">
                    {{(selectedPortfolio.data.basket.items[camelCase(instrument.name)] || {}).position || 0}}
                  </span> 
                </td>
                <td>
                  <span v-if="selectedPortfolio.data.basket">
                    {{(selectedPortfolio.data.basket.items[camelCase(instrument.name)] || {}).proposedPosition || 0}}
                  </span>
                </td>
                <td>
                  <span v-if="selectedPortfolio.data.basket">
                    {{((selectedPortfolio.data.basket.items[camelCase(instrument.name)] || {}).dailyReturn || 0).toFixed(2)}}
                  </span>
                </td>
                <td>
                  <span v-if="selectedPortfolio.data.basket">
                  {{((selectedPortfolio.data.basket.items[camelCase(instrument.name)] || {}).monthlyReturn || 0).toFixed(2)}}
                  </span>
                </td>
                <td>
                  <span v-if="selectedPortfolio.data.basket">
                    {{((selectedPortfolio.data.basket.items[camelCase(instrument.name)]  || {}).totalReturn || 0).toFixed(2)}}
                  </span>
                </td>
                <td class="text-center col text-nowrap" v-if="!impersonation">
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="html2pdf__page-break" />
        <!-- Instruments Chart <<<< -->

        <!-- Weights Pie Chart >>>> -->
        <div style="margin:50px">
          <weights 
            :no-card="true"
            :weights="compositionWeights" 
            :children="selectedItem.originalInstruments || selectedItem.instruments" 
            :pdf="true"
          />
        </div>
        <!-- Weights Pie Chart <<<< -->

      </section>
    </vue-html2pdf>
  </div>
  
</template>