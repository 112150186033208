<script>
import Layout from "../../layouts/horizontal";

import { mapGetters, mapActions, mapState } from 'vuex';
import Swal from "sweetalert2";
import RiskProfileEdit from './risk-profile-edit'
import RiskProfileAnswers from './risk-profile-answers'
import Portfolios from '@/views/pages/portfolios/home-widget'
import VueRssFeed from "@/components/rssfeed";
import simplebar from "simplebar-vue";

export default {
  components: {
    Layout,

    RiskProfileEdit,
    RiskProfileAnswers,
    Portfolios,
    VueRssFeed,
    simplebar
  },
  props: {
    userid: {
      type: String
    }
  },
  data() {
    
    return {
      initialized: false,
      feed:{
        feedUrl: null,
        name: "",
        limit: 10,
      },
      editingProfile: null,
      showProfileAnswers: null,
      title: '',
      breadcrumbs: [
        
        {
          text: 'Portfolio',
          active: true
        }
      ]
    };
  },
  created() {
   
    if(process.env.VUE_APP_TYPE === 'CRYPTO') {
      this.feed.feedUrl = process.env.VUE_APP_BASE_URL + "/api/rss/cointelegraph";
    } else {
      this.feed.feedUrl = process.env.VUE_APP_BASE_URL + "/api/rss/yahoo";
    }
    this.init();
    
  },
  computed: {
    ...mapState('signalr', {
      connectionId:'connectionId'
    }),
    isBusy (){
      return this.loading || this.portfoliosLoading;
    },
    ...mapGetters('auth', {
      token: 'token',
      permissions: 'permissions'
    }),
    ...mapGetters('portfolios', {
      portfoliosLoading: 'loading'
    }),
    ...mapGetters('riskprofiles', {
      loading: 'loading',
      deleting: 'deleting',
      riskProfiles: 'items'
    })
  },
  watch: {
    connectionId(newValue, oldValue) {
      if(newValue && (newValue !== oldValue)) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions('riskprofiles', {
      loadProfiles: 'load',
     
      deleteProfile: 'delete',
    }),
    ...mapActions('portfolios', {
      loadUserPortfolios: 'loadUserPortfolios',
      
    }),
    async init() {
    
      
      if(this.connectionId) {
        this.initialized = true;
        
        if(!this.userid) {
          await this.loadProfiles();
        }
        await this.loadUserPortfolios({ userId: this.userid });
      } else {
        console.log('clientId is null. waiting...');
      }
    },
    tryToDelete(id) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
          this.deleteProfile({ id }).then(() => {
            Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
          });
        }
      });
    },
    hideProfileEditForm (){
      this.editingProfile = null;
    },
    showProfileEditForm (profile) {
      this.editingProfile = profile;
    },
    hideProfileAnswersForm (){
      this.showProfileAnswers = null;
    },
    showProfileAnswersForm (profile) {
      this.showProfileAnswers = profile;
    }
  }
};
</script>

<template>
  <Layout>

    <risk-profile-edit @close="hideProfileEditForm" v-if="editingProfile" :profile="editingProfile">

    </risk-profile-edit>

    <risk-profile-answers @close="hideProfileAnswersForm" v-if="showProfileAnswers" :profile="showProfileAnswers">

    </risk-profile-answers>

    <div class="row">
      <div class="col-12">
         <div class="email-leftbar p-0" >
         
            <div class="card p-0 cardc8" v-if="!userid && permissions && permissions.canUsePredefinedProfilesOnly != true">
              <div class="card-content m-3">
                <div class="card-header bg-transparent d-flex justify-content-between"><h5>Risk Profiles</h5></div>
                <router-link class="btn-block btn btn-c8 mb-4" to="/risk-profile-new">
                  <span class="btn-content"><i class="fa fa-plus mr-2"/> Create a New Risk Profile</span>
                </router-link>
                <div class="list-group">
                  <a href="javascript:void(0)" 
                    class="list-group-item  text-dark" 
                    v-for="riskProfile in riskProfiles" 
                    :key="riskProfile.id"
                  >
                    <div>
                      <div class="float-left" @click="showProfileAnswersForm(riskProfile)">{{riskProfile.name}}</div>
                      <i class="fa fa-spinner fa-spin mr-2 " v-if="riskProfile.calculationStatus === 'Pending'" title="Pending"/>
                      <i class="fa fa-spinner fa-spin mr-2 text-primary" v-if="riskProfile.calculationStatus === 'Calculating'" title="Calculating"/>
                      <i class="fa fas fa-exclamation-triangle mr-2 text-danger" v-if="riskProfile.calculationStatus === 'Failed'" title="Calculation Failed"/>

                      <div class="float-right">
                        <a href="javascript:void(0)" 
                          class="text-primary font-size-14 mr-3" 
                          title="Edit name"
                          @click="showProfileEditForm(riskProfile)"
                        >
                          <i class="fa fa-pen"></i>
                        </a>
                        <a href="javascript:void(0)" 
                          class="text-danger font-size-14" 
                          :disable="deleting"
                          title="Delete" 
                          @click="tryToDelete(riskProfile.id)">

                          <i class="fa fa-trash" v-if="deleting !== riskProfile.id"/>
                          <i class="fa fa-spin fa-spinner" v-if="deleting === riskProfile.id"/>
                        </a>
                      </div>
                    </div>
                    <small class="text-muted">
                      {{riskProfile.errorMessage}}
                    </small>
                  </a>
                </div>
                <div v-if="riskProfiles.length === 0">
                  <em class="text-muted">You don't have any profiles yet.</em>
                </div>
              </div>
            </div>
             <div class="card p-0 cardc8 mt-2 rss-feed">
              <div class="card-content  p-0"> 
                <div class="mt-3 ml-3 mr-3 card-header bg-transparent d-flex justify-content-between"><h5>News</h5></div>
                <simplebar class="p-2" style="max-height: 600px;">
                  <VueRssFeed :feedUrl="feed.feedUrl" :name="feed.name" :limit="feed.limit"/>
                </simplebar>
               </div>
            </div> 
          </div>
         <div class="email-rightbar mb-3 p-0">
            <portfolios :impersonation="!!userid"></portfolios>
        </div>
      </div>
    </div>
 </Layout>
</template>