<script>
import {Chart} from 'highcharts-vue'
import { chartColors } from '@/helpers'

export default {
   components: {
    Highcharts: Chart
  },
  props: {
    embedded: {
      type: Boolean,
      required: false
    },
    stat: {
      type: Array,
      required: false
    },
    instruments: {
      type: Array,
      required: true
    },
    colors:{
      type: Object,
      required: true
    },
    hideChartHint: {
      type: Boolean,
      required: false
    },
    pdf: {
      type: Boolean,
      required: false
    }
  },
  created(){
 
  },
  data() {
    return {
      colorIndex: 0,
      mainChartYAxisLogarithmicType: false
    };
  },
  computed:{
    chartOptions() {
      if(!this.hasData)
        return null;

      let chartSeries = [...this.stat || []];
      let seriesOptions = [];
      for(let i = 0; i < chartSeries.length; i++){
        
        const source = chartSeries[i];
        const lineWidth = 1;
        seriesOptions[i] = {
          name: source.title + (source.isLive ? ' [Live]' : ''),
          label: source.title,
          data: (source.points || []).map(x => [x.timeStamp, x.value]),
          color: source.isLive ? "#7d096d" : this.colors[source.indexId],
          lineWidth: lineWidth
        };
      }

      let opts = {
        credits:{
           enabled: false
        },
        legend:{
          enabled: true
        },
        exporting: {
          buttons: {
              contextButton: {
                  menuItems: [
                  "viewFullscreen", 
                  "printChart", 
                  "separator", 
                  "downloadPNG", 
                  "downloadJPEG", 
                  "downloadPDF", 
                  "downloadSVG",
                  "separator",
                  "downloadXLS",
                  "downloadCSV",
                  "viewData" ]
              }
          }
        },
        rangeSelector: {
            selected: 5
        },
        xAxis: {
          type: 'datetime',
        
          labels: {
              
          },
        
        },
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        plotOptions: {
          series: {
            showInNavigator: true
          }
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2,
          xDateFormat:  '%d/%m/%Y'
        },
        colors: chartColors,
        series: seriesOptions
      };
     
      return opts;
    },
    hasData() {
      return this.stat.length > 0 && this.stat.filter(x => x.points.length > 0).length > 0;
    }
  }
};
</script>

<template>
<div v-if="hasData">
  <div  v-if="embedded" >
    <div class="row">
    
      <div class="col text-right">
        <b-form-checkbox 
          v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
          style="display:inline-block;"
        >

        </b-form-checkbox>
        Logarithmic Y axis
      </div>
    </div>

    <h6 class="card-subtitle mb-2 mt-1 text-muted" v-if="!hideChartHint">
      <span>* Live Track Record in <b>Bold</b>. This Index is not a benchmark within the meaning of the Benchmarks Regulation issued by ESMA</span>
    </h6>

    <em class="text-muted" v-if="!hasData">No data found...</em>
    
    <highcharts 
      v-if="hasData" 
      :constructorType="'stockChart'" 
      class="hc" 
      :options="chartOptions" 
      ref="chart"
    >
    </highcharts> 
  </div>
  <div class="card cardc8" style="height:100%;  " v-if="!embedded">
    
    <div class="card-content" >
      <div class="card-body">
 
        <!-- Header -->
        <div class="row" v-if="!pdf">
          <div class="col">
            <h5 class="card-title">Performance</h5>
          </div>
          <div class="col text-right">
            <b-form-checkbox 
              v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
              style="display:inline-block;"
            >
    
            </b-form-checkbox>
            Logarithmic Y axis
          </div>
        </div>

        <h6 class="card-subtitle mb-2 mt-1 text-muted" v-if="!hideChartHint">
          <span>* Live Track Record in <b>Bold</b>. This Index is not a benchmark within the meaning of the Benchmarks Regulation issued by ESMA</span>
        </h6>

        <em class="text-muted" v-if="!hasData">No data found...</em>
        
        <highcharts 
          v-if="hasData" 
          :constructorType="'stockChart'" 
          class="hc" 
          :options="chartOptions" 
          ref="chart"
        >
        </highcharts> 

      </div>
    </div>
  </div>
</div>
</template>